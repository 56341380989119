$(document).ready(function ()
{
	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});



	/*
	 *	SLIDERS
	 */

	$('#slider-nav').flexslider({
		animation: "slide",
		controlNav: false,
		animationLoop: false,
		slideshow: false,
		itemWidth: 120,
		itemMargin: 20,
		asNavFor: '#slider-main'
	});

	$('#slider-main').flexslider({
		animation: "slide",
		controlNav: false,
		animationLoop: false,
		slideshow: false,
		sync: "#slider-nav"
	});



	/*
	 *	GRID FILTER
	 */

	var $grid = $('.iso-grid');
	var start_filter = '*';

	if (getHashFilter() != null) {
		start_filter = '.'+getHashFilter();
	}

	$grid.isotope({ filter: start_filter });

	$('.iso-filter a').click(function(){
		var filter_string = $(this).attr('data-filter');
		$grid.isotope({filter: filter_string});
	});

	function getHashFilter() {
		var hash = location.hash;
		// get filter=filterName
		var matches = location.hash.match( /#([^&]+)/i );
		var hashFilter = matches && matches[1];
		return hashFilter && decodeURIComponent( hashFilter );
	}

});

$(window).load(function(){
	// Event forms
	$('.event-collapsible').addClass('collapse');
});