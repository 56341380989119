/*
*	Gebruiksaanwijzingen webshop
*
*	Toevoegen winkelmandje:
*	data-webshop-action="add-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal toe te voegen items]"

*	Verwijderen uit winkelmandje:
*	data-webshop-action="remove-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal te verwijderen items]"
*
*	Winkelmandje weergeven:
*	data-webshop-component="basket-amount"
*	data-webshop-component="basket-total"
*
*	data-webshop-component="basket-dropdown"
*
*		data-webshop-component="basket-dropdown-list"
*		-> Onder dit element een element met klasse "template-row" en "display:none" geplaatst
*			Dit element wordt dan gebruikt als basis voor product
*
*			Placeholders voor template-row:
*			- [[product_title]]
*			- [[product_amount]]
*			- [[product_total_price]]
*			- [[product_price]]
*			- [[product_id]]
*			- [[product_image_url]]
*
*/

$(document).ready(function ()
{
	//Knoppen initialiseren
	init_webshop_actions();

	//Werking checkout initialiseren
	init_checkout_billing();

	//Winkelmandje ophalen
	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/get', function(result){
		fill_basket(result);
	});
});

function init_checkout_billing(){
	if ($('#billing-address-same').length > 0) {
		$billing_check = $('#billing-address-same');

		if ($billing_check.is(':checked')) {
			$('#ws-billing-address').hide();
		}

		$billing_check.change( function(){
			if ($billing_check.is(':checked')) {
				$('#ws-billing-address').slideUp(150);
			} else {
				$('#ws-billing-address').slideDown(150);
			}
		});
	}
}

function init_webshop_actions(){
	$('[data-webshop-action="add-item"]').off('click', add_product_to_basket);
	$('[data-webshop-action="add-item"]').on('click', add_product_to_basket);
}

function fill_basket(basket){
	$('[data-webshop-component="basket-dropdown"]').each(function(index, el){
		$(el).find('[data-webshop-component="basket-dropdown-list"]').html('');
		var product_count = 0;
		var total_price = 0;
		if (Object.prototype.toString.call( basket.products ) === '[object Object]') {
			$.each(basket.products, function(id, product){
				$template = $(el).find('.template-row').clone();
				$template.removeClass('template-row');

				$template.html(replaceAll($template.html(),'[[product_title]]', product.item.item_title));
				$template.html(replaceAll($template.html(),'[[product_total_price]]', (parseFloat(Math.round(product.item.field_price.value*product.amount * 100) / 100).toFixed(2))));
				$template.html(replaceAll($template.html(),'[[product_price]]', parseFloat(Math.round(product.item.field_price.value * 100) / 100).toFixed(2)));
				$template.html(replaceAll($template.html(),'[[product_amount]]', product.amount));
				$template.html(replaceAll($template.html(),'[[product_id]]', product.item.itemid));

				total_price += (product.item.field_price.value*product.amount);

				var image_url = "/placeholder/500x500";
				if (product.item.attachments.length > 0) {
					image_url = product.item.attachments[0].attachment_directory + product.item.attachments[0].attachment_slug;
				}

				$template.html(replaceAll($template.html(),'[[product_image_url]]', image_url));

				$template.find('[data-webshop-action="remove-item"]').on('click', remove_product_from_basket);
				$template.find('[data-webshop-action="change-amount"]').on('change', change_product_amount);

				$template.css('display', '');
				$(el).find('[data-webshop-component="basket-dropdown-list"]').append($template);
				product_count++;
			});

			$('[data-webshop-component="basket-total"]').html(parseFloat(Math.round(total_price * 100) / 100).toFixed(2));
			$('[data-webshop-component="basket-amount"]').html(product_count);
		}

	});


	init_webshop_actions();
}

function change_product_amount(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).val();

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/update/'+itemid+'/'+amount, function(result){
		fill_basket(result);
	});
}

function add_product_to_basket(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).attr('data-item-amount');

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/add/'+itemid+'/'+amount, function(result){
		fill_basket(result);
	});
}

function remove_product_from_basket(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).attr('data-item-amount');

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/remove/'+itemid+'/'+amount, function(result){
		fill_basket(result);
	});
}

function escapeRegExp(str) {
	if (typeof str != 'undefined') {
		return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}else{
		return '';
	}
}

function replaceAll(str, find, replace) {
	if (typeof str != 'undefined') {
		return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
	}else{
		return '';
	}
}